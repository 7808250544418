import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BackendCallService {

  constructor( private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient) { }

  getHeaders(hasUpload) {
    let token = localStorage.getItem('currentUser');
    if (token) {
      if(hasUpload){
        return {
          headers: new HttpHeaders({
            Authorization: token
          }),
        }
      }else{
        return {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            Authorization: token
          }),
        };
      }
    } else {
      return {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      };
    }
  }

  handleResponse(response) {
    if (response.length > 0) {
      let resp = response.map((x) => x['attributes']);
      return resp;
    }
    return response;
  }

  postRequest(url: string, params_body,hasUpload=false) {
    let path = '/api' + url;
    let headers = this.getHeaders(hasUpload);
    return this.http.post<any>(path, params_body, headers);
  }

  getRequest(url: string,page=false) {
    if(page) {
      url = url+'?page='+page
    }
    let path = '' + url;
    let headers = this.getHeaders(false);
    return this.http.get<any>(path, headers);
  }

  patchRequest(url: string, params) {}
  putRequest(url: string, params_body) {
    let token = localStorage.getItem('currentUser');
    let path = '' + url;
    let headers = { headers: new HttpHeaders({ Authorization: token }) };
    return this.http.put<any>(path, params_body, headers);
  }

  deleteRequest(url: string) {
    let token = localStorage.getItem('currentUser');
    let path = ''+ url;
    let headers = { headers: new HttpHeaders({ Authorization: token }) };
    return this.http.delete<any>(path, headers);
  }

  async AllContacts(page=false) {
    let data = await this.getRequest('/api/contacts',page).toPromise();
    let dataArray = data.contacts
    let contactsdata = {contact:dataArray, totalPages: data.totalPages, totalItems: data.totalItems, currentPage: data.currentPage }
    return contactsdata
  }

  async AllSubscribers(page=false) {
    let data = await this.getRequest('/api/subscriber',page).toPromise();
    let dataArray = data.subscribers
    let subscriberdata = {subscriber:dataArray, totalPages: data.totalPages, totalItems: data.totalItems, currentPage: data.currentPage }
    return subscriberdata
  }

}
