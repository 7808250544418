import { Component, OnInit,HostListener } from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl, FormArray, NgForm} from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {SubscriberService} from './newsletter.service'
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements OnInit {
  add_class = false;
  submitted = false;
  subscriberForm: FormGroup
  constructor(private service: SubscriberService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.subscriberForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    });
  }

  get f() {
    return this.subscriberForm.controls;
  }

  onSubmitSubscriber() {
    this.submitted = true;
    if (this.subscriberForm.invalid) {
      return;
    }
    const email = this.subscriberForm.value.email;
    let bodyParams = {
      email: email
    }
    this.service.createSubscriber(bodyParams).subscribe(
      (data) => {
        this.openAlertSuccess();
        // this.handleResponse(data);
      }
    );
  }

  openAlertSuccess() {
    Swal.fire('Successfully!', 'You have successfully susbcribed.', 'success');
  } 

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerWidth;
    if(event.target.innerWidth <= 767) {
      this.add_class = true
    }
    else {
      this.add_class = false
    }
  }
}
