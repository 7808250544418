import { Injectable } from '@angular/core';
import { HttpClient,HttpEvent,HttpInterceptor,HttpHandler,HttpRequest,HttpResponse,HttpErrorResponse} from '@angular/common/http';
import {
  Router,
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { BackendCallService } from './../../../backend-call.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js';7
@Injectable({
    providedIn: 'root'
})
export class SubscriberService {
    errorMessage : any;
    constructor(private router: Router,
    private backendCall: BackendCallService,) { }

    createSubscriber(bodyParams) {
        return this.backendCall.postRequest('/subscribers', bodyParams).pipe(catchError(this.handleError));
    }

    handleError(error: HttpErrorResponse){
        this.errorMessage = '';
       if (error.error instanceof ErrorEvent) {
         // client-side error
         this.errorMessage = `${error}`;
         Swal.fire('Error!',this.errorMessage, 'error');
    
       } else {
         // server-side error
         this.errorMessage =  `${error}`;
         Swal.fire('Error!',this.errorMessage, 'error');
       }
       return throwError(this.errorMessage);
      }

}