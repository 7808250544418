import { Injectable } from '@angular/core';
import { HttpClient,HttpEvent,HttpInterceptor,HttpHandler,HttpRequest,HttpResponse,HttpErrorResponse} from '@angular/common/http';
import {
  Router,
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
  ActivatedRoute
} from '@angular/router';
import { Observable } from 'rxjs';
import { BackendCallService } from './../../backend-call.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Injectable({
  providedIn: 'root'
})
export class AuthLayoutService {
  errorMessage:any;
  constructor(private router: Router,private backendCall: BackendCallService,private activatedRoute: ActivatedRoute,
    private http:HttpClient) { }

  SigninUser(body) {
    return this.backendCall.postRequest('/auth/signin', body)
    .pipe(
      catchError(this.handleError)
    );
  }

  isAuthenticated() {
    let user = localStorage.getItem('currentUser')
    if (!user){
      setTimeout (() => {
        Swal.fire('Error!','Please Login to your accout', 'error');
     }, 9000);
      window.location.href = "/auth/admin/signin"
    }
    return user
  }

  logout() {
    let role = localStorage.getItem('role');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('userId');
    localStorage.removeItem('username');
    localStorage.removeItem('userImage');
    localStorage.removeItem('role');
    if(role == 'ROLE_ADMIN'){
      window.location.href = "/auth/admin/signin"
    }
  }

  handleError(error: HttpErrorResponse){
    this.errorMessage = '';
   if (error.error instanceof ErrorEvent) {
     // client-side error
     this.errorMessage = `${error}`;
     Swal.fire('Error!',this.errorMessage, 'error');

   } else {
     // server-side error
     this.errorMessage =  `${error}`;
     Swal.fire('Error!',this.errorMessage, 'error');
   }
   return throwError(this.errorMessage);
  }
}
