import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,Router } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthLayoutService} from './auth-layout.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthLayoutService, private router: Router) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let userValue = this.authService.isAuthenticated()
      {
        if(userValue) {
          return true;
        }
      }
      // this.router.navigate(['auth/admin/signin'], { queryParams: { returnUrl: state.url }});
    return false;
  }
  
}
