<div class="parent">
    <div class="error-page text-center">
        <!-- Title -->
        <h1 class="title">404</h1>
        <!-- /title -->

        <p class="display-2 text-dark mb-7">Sorry! The page not found</p>
        <p class="mb-10 fnt-size">The Link You Followed Probably Broken, or the page has been removed.</p>
    </div>
</div>
