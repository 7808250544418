<header id="topnav" class="defaultscroll sticky nav-light" (window:scroll)="windowScroll()" *ngIf = "!adminHeader">
  <div class="container">
    <div>
      <a class="logo nav-light" routerLink="/" >
        <div *ngIf="!isLanding; else darkLogo">
          <!-- <img src="assets/images/logo-light.png" class="l-light" height="24" alt=""> -->
          <img src="assets/images/logo-dark.png" class="l-dark" height="24" alt="">
        </div>
        <ng-template #darkLogo>
          <!-- <img src="assets/images/logo-dark.png" class="l-light" height="24" alt=""> -->
          <img src="assets/images/logo-dark.png" class="l-dark" height="24" alt="">
        </ng-template>
      </a>
    </div>
    <div class="buy-button">
      <a href="javascript: void(0);" class="btn btn-primary login-btn-primary">Get a Qoute</a>
      <a href="javascript: void(0);" class="btn btn-primary login-btn-light">Get a Qoute</a>
    </div>
    <div class="menu-extras">
      <div class="menu-item">
        <!-- Mobile menu toggle-->
        <a class="navbar-toggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
        <!-- End mobile menu toggle-->
      </div>
    </div>

    <div id="navigation">
      <!-- Navigation Menu-->
      <ul class="navigation-menu {{navClass}}">
        <li class="{{activeUrl == '/projects-and-portfolios' ? 'active' : ''}}" [routerLinkActiveOptions]="{exact: false}" routerLinkActive="active">
          <a routerLink="/projects-and-portfolios" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" class="nav-link-ref" (click)="toggleMenu()">Portfolio</a>
        </li>
        <li class="has-submenu" class="{{activeUrl == '/services/all' ? 'active' : ''}}" [routerLinkActiveOptions]="{exact: false}" routerLinkActive="active">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">Services</a><span class="menu-arrow"></span>
          <ul class="submenu megamenu">
            <li>
              <ul>
                <li [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active"><a class="font-weight-bold" [routerLink]="['/services/all']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Services & Technology Overview</a></li>
                <li [routerLinkActiveOptions]="{exact: false}" routerLinkActive="active"><a class="nav-link-ref" [routerLink]="['/services/mean-stack']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">MEAN Stack</a></li>
                <li [routerLinkActiveOptions]="{exact: false}" routerLinkActive="active"><a class="nav-link-ref" [routerLink]="['/services/mern-stack']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">MERN Stack</a></li>
                <li [routerLinkActiveOptions]="{exact: false}" routerLinkActive="active"><a class="nav-link-ref" [routerLink]="['/services/full-stack']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">Full Stack Web Development</a></li>
                <li [routerLinkActiveOptions]="{exact: false}" routerLinkActive="active"><a class="nav-link-ref" [routerLink]="['/services/mobile-app-development']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">Mobile App Development</a></li>
                <li [routerLinkActiveOptions]="{exact: false}" routerLinkActive="active"><a class="nav-link-ref" [routerLink]="['/services/seo-services']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">SEO Services</a></li>
                <li [routerLinkActiveOptions]="{exact: false}" routerLinkActive="active"><a class="nav-link-ref" [routerLink]="['/services/ux-tools']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">UX/UI Tools</a></li>
                <li [routerLinkActiveOptions]="{exact: false}" routerLinkActive="active"><a class="nav-link-ref" [routerLink]="['/services/web-solutions']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">Customised Web Solutions</a></li>
                <li [routerLinkActiveOptions]="{exact: false}" routerLinkActive="active"><a class="nav-link-ref" [routerLink]="['/services/server-management']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">Server Management & DevOps</a></li>
                <li [routerLinkActiveOptions]="{exact: false}" routerLinkActive="active"><a class="nav-link-ref" [routerLink]="['/services/software-maintenance']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">Software Maintenance</a></li>
                <li [routerLinkActiveOptions]="{exact: false}" routerLinkActive="active"><a class="nav-link-ref" [routerLink]="['/services/databases']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">Databases Administration</a></li>
                <li [routerLinkActiveOptions]="{exact: false}" routerLinkActive="active"><a class="nav-link-ref" [routerLink]="['/services/blockchain']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">Blockchain Development</a></li>
              </ul>
            </li>
          </ul>
        </li>
        <li class="has-submenu" class="{{activeUrl == '/model' ? 'active' : ''}}" [routerLinkActiveOptions]="{exact: false}" routerLinkActive="active">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">Working Models</a><span class="menu-arrow"></span>
          <ul class="submenu megamenu">
            <li>
              <ul>
                <li [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active"><a class="nav-link-ref" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" routerLink="/working-models/resource-outsourcing">Resources Outsourcing</a></li>
                <li [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active"><a class="nav-link-ref" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" routerLink="/working-models/resource-engagement">Per Resource Engagement</a></li>
                <li [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active"><a class="nav-link-ref" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" routerLink="/working-models/dedicated-team">Dedicated Team Model</a></li>
                <li [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active"><a class="nav-link-ref" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" routerLink="/working-models/maintenance-contracts">Maintenance Contracts</a></li>
                <li [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active"><a class="nav-link-ref" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" routerLink="/working-models/fixed-price">Fixed Price & Timeline</a></li>
              </ul>
            </li>
          </ul>
        </li>
        <li class="{{activeUrl == '/contact-us' ? 'active' : ''}}">
          <a routerLink="/contact-us" class="nav-link-ref" (click)="toggleMenu()">Contact Us</a>
        </li>
        <li class="{{activeUrl == '/about' ? 'active' : ''}}">
          <a routerLink="/about" class="nav-link-ref" (click)="toggleMenu()">About Us</a>
        </li>
      </ul>
      <!--end navigation menu-->
      <div class="buy-menu-btn d-none">
        <a href="javascript: void(0);" target="_blank" class="btn btn-primary">Buy Now</a>
      </div>
      <!--end login button-->
      <!--end login button-->
    </div>
    <!--end navigation-->
  </div>
  <!--end container-->
</header>

<header id="topnav" class="defaultscroll sticky nav-light" (window:scroll)="windowScroll()" *ngIf = "adminHeader">
  <div class="container">
    <div>
      <a class="logo nav-light" routerLink="/" >
        <div *ngIf="!isLanding; else darkLogo">
          <!-- <img src="assets/images/logo-light.png" class="l-light" height="24" alt=""> -->
          <img src="assets/images/logo-dark.png" class="l-dark" height="24" alt="">
        </div>
        <ng-template #darkLogo>
          <!-- <img src="assets/images/logo-dark.png" class="l-light" height="24" alt=""> -->
          <img src="assets/images/logo-dark.png" class="l-dark" height="24" alt="">
        </ng-template>
      </a>
    </div>
    <div class="buy-button">
      <a href="javascript: void(0);" class="btn btn-primary login-btn-primary" (click)="logout($event)">Logout</a>
      <a href="javascript: void(0);" class="btn btn-primary login-btn-light" (click)="logout($event)">Logout</a>
    </div>
    <div class="menu-extras">
      <div class="menu-item">
        <a class="navbar-toggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
      </div>
    </div>
    <div id="navigation">
      <ul class="navigation-menu {{navClass}}">
        <li class="{{activeUrl == '/admin/dashboard' ? 'active' : ''}}">
          <a routerLink="/admin/dashboard" class="nav-link-ref" (click)="toggleMenu()">Dashboard</a>
        </li>
        <li class="{{activeUrl == '/admin/dashboard/contacts' ? 'active' : ''}}">
          <a routerLink="/admin/dashboard/contacts" class="nav-link-ref" (click)="toggleMenu()">Contact Requests</a>
        </li>
        <li class="{{activeUrl == '/admin/dashboard/subscribers' ? 'active' : ''}}">
          <a routerLink="/admin/dashboard/subscribers" class="nav-link-ref" (click)="toggleMenu()">Subscribers</a>
        </li>
        <!-- <li><a routerLink="/careers" class="nav-link-ref">Career</a></li> -->
      </ul>
      <div class="buy-menu-btn d-none">
        <a href="javascript: void(0);" target="_blank" class="btn btn-primary">Buy Now</a>
      </div>
    </div>
  </div>
</header>
