import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { CarouselModule } from 'ngx-owl-carousel-o';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule,HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import {ErrorInterceptor} from './components/_helpers/error.interceptor'
// import{JwtInterceptor} from './components/_helpers/jwt.interceptor';
import {CanloadGuard} from './components/auth-layout/canload.guard';
import {AuthGuard} from './components/auth-layout/auth.guard';
import { NotFoundComponent } from './components/not-found/not-found.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: true
};
const routes: Routes = [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'landing'
    },
    {
      path: '',
      loadChildren: () => import('./components/default-layout/default-layout.module').then(m => m.DefaultLayoutModule)
    },

    {
      path: 'auth',
      loadChildren: () => import('./components/auth-layout/auth.module').then(m => m.AuthModule)
    },
    {
      path: 'admin',
      loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule),
      canActivate: [AuthGuard],
      canLoad:[CanloadGuard]
    },  
    {
      path: '**',
      component: NotFoundComponent
    }
  ];

@NgModule({
  declarations: [
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    CarouselModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    HttpClientModule,
    CommonModule,
    FeatherModule.pick(allIcons),
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled'
    }),
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    CanloadGuard,AuthGuard
  ],
  exports: [FeatherModule, RouterModule],
})

export class AppRoutingModule { }
