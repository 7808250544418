import { Component, OnInit, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import { AuthLayoutService } from '../../auth-layout/auth-layout.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  navClass: any = '';
  activeUrl: any = '';
  isLanding = true;
  isCondensed = false;
  state: any;
  adminHeader = false;
  open:string = '';
  constructor(private router: Router,private service: AuthLayoutService) {
    router.events.subscribe((router_event) => {
      this.adjustHeader(router_event);
    });
  }

  ngOnInit(): void {
  }

  adjustHeader(router_event): void {
    if (router_event instanceof NavigationEnd) {
      this.activeUrl = router_event.url;
      let url = this.activeUrl.split('/')
      if(url.length == 3) {
        let TempState = url.pop();
        this.state = url.pop();
        if(this.state == 'admin') {
          this.adminHeader = true;
        }
      }
      else if (url.length == 4) {
        let TempState = url.pop();
        let TempState2 = url.pop();
        this.state = url.pop();
        if(this.state == 'admin') {
          this.adminHeader = true;
        }
      }
      else if (url.length > 4) {
        let TempState = url.pop();
        let TempState2 = url.pop();
        let TempState3 = url.pop();
        this.state = url.pop();
        if(this.state == 'admin') {
          this.adminHeader = true;
        }
      }
      if (this.activeUrl === '/') {
        // this.navClass = 'nav-light';
        this.isLanding = false;
      } else {
        this.navClass = '';
        this.isLanding = true;
      }
    }
  }

  windowScroll(): void {
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
      document.getElementById('topnav').classList.add('nav-sticky');
    } else {
      document.getElementById('topnav').classList.remove('nav-sticky');
    }

    if (document.getElementById('back-to-top')) {
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        document.getElementById('back-to-top').style.display = 'inline';
      } else {
        document.getElementById('back-to-top').style.display = 'none';
      }
    }
  }

  toggleMenu(): void {
    this.isCondensed = !this.isCondensed;
    if (this.isCondensed) {
      document.getElementById('navigation').style.display = 'block';
    } else {
      document.getElementById('navigation').style.display = 'none';
    }
  }

  onMenuClick(event): boolean {
    event.preventDefault();
    const nextEl = event.target.nextSibling.nextSibling;
    if (nextEl && !nextEl.classList.contains('open')) {
      const parentEl = event.target.parentNode;
      if (parentEl) {
        parentEl.classList.remove('open');
      }
      nextEl.classList.add('open');
    } else if (nextEl) {
      nextEl.classList.remove('open');
    }
    return false;
  }

  logout(e) {
    this.service.logout();
    e.preventDefault();
  }
}
