import { Component,OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute,NavigationEnd } from '@angular/router';
import { filter, map,mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta
  ) { }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
        var active_route = this.getChild(this.activatedRoute)
        active_route.data.subscribe(data => {
          console.log(data);
          this.titleService.setTitle(data.title)
 
          if (data.descrption) {
            this.metaService.updateTag({ name: 'description', content: data.descrption })
          } else {
            this.metaService.removeTag("name='description'")
          }
 
          // if (data.robots) {
          //   this.metaService.updateTag({ name: 'robots', content: data.robots })
          // } else {
          //   this.metaService.updateTag({ name: 'robots', content: "follow,index" })
          // }

          if (data.keywords) {
            this.metaService.updateTag({ name: 'keywords', content: data.keywords })
          } else {
            this.metaService.removeTag("name='keywords'")
          }
        })
 
      }) 
  }

  onActivate(event) {
    let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
            window.scrollTo(0, pos - 200);
        } else {
            window.clearInterval(scrollToTop);
        }
    }, 1);
}

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
 
  }
}
