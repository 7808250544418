import { NgModule,CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA} from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { NgxPaginationModule } from 'ngx-pagination';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FeatherModule } from 'angular-feather';
import {CommonModule} from '@angular/common';
import { allIcons } from 'angular-feather/icons';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import { DefaultLayoutComponent } from './components/default-layout/default-layout.component';
import {DashboardComponent} from './components/dashboard/dashboard.component'
import { HeaderComponent } from '../app/components/shared/header/header.component';
import { FooterComponent } from '../app/components/shared//footer/footer.component';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: true
};
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DefaultLayoutComponent,
    DashboardComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPaginationModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    FeatherModule.pick(allIcons),
    SweetAlert2Module.forRoot(),
  ],
  providers: [Meta,{
    provide: PERFECT_SCROLLBAR_CONFIG,
    useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
  }],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent],
  exports:[FeatherModule],
})

export class AppModule { }
