import { Injectable } from '@angular/core';
import { CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,Router,Route,UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthLayoutService} from './auth-layout.service';

@Injectable({
  providedIn: 'root'
})
export class CanloadGuard implements CanLoad  {
  constructor(private authService: AuthLayoutService, private router: Router) {
  }
  canLoad(route: Route, segments: UrlSegment[]):boolean{
    let userValue = this.authService.isAuthenticated()
    {
      if(userValue) {
        return true;
      }
    }
    return false;
  }
  
}
