  <!-- Footer Start -->
  <!-- <div class="position-relative">
    <div class="shape overflow-hidden text-footer">
      <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
      </svg>
    </div>
  </div> -->
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
          <h4 class="text-light footer-head">Services</h4>
          <ul class="list-unstyled footer-list mt-4 {{add_class ? 'row-spacer' : ''}}">
            <li>
              <a routerLink="/services/all" class="text-foot"><i class="uil uil-angle-right"></i> Services & Technology
                Overview</a>
            </li>
            <li><a routerLink="/services/mean-stack" class="text-foot"><i class="uil uil-angle-right"></i> MEAN Stack</a>
            </li>
            <li><a routerLink="/services/mern-stack" class="text-foot"><i class="uil uil-angle-right"></i> MERN Stack</a>
            </li>
            <li><a routerLink="/services/full-stack" class="text-foot"><i class="uil uil-angle-right"></i> Full Stack Web
                Development</a></li>
            <li><a routerLink="/services/mobile-app-development" class="text-foot"><i class="uil uil-angle-right"></i>
                Mobile App Development</a></li>
            <li><a routerLink="/services/seo-services" class="text-foot"><i class="uil uil-angle-right"></i> SEO
                Services</a></li>
            <li><a routerLink="/services/ux-tools" class="text-foot"><i class="uil uil-angle-right"></i> UX/UI Tools</a>
            </li>
            <li><a routerLink="/services/web-solutions" class="text-foot"><i class="uil uil-angle-right"></i> Customised
                Web Solutions</a></li>
            <li><a routerLink="/services/server-management" class="text-foot"><i class="uil uil-angle-right"></i> Server
                Management & DevOps</a></li>
            <li><a routerLink="/services/software-maintenance" class="text-foot"><i class="uil uil-angle-right"></i>
                Software Maintenance</a></li>
            <li><a routerLink="/services/databases" class="text-foot"><i class="uil uil-angle-right"></i> Databases
                Administration</a></li>
          </ul>
        </div>
        <div class="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
          <h4 class="text-light footer-head">Working Models</h4>
          <ul class="list-unstyled footer-list mt-4 row-spacer">
            <li><a routerLink="/working-models/resource-outsourcing" class="text-foot"><i class="uil uil-angle-right"></i>
                Resources Outsourcing</a></li>
            <li><a routerLink="/working-models/resource-engagement" class="text-foot"><i class="uil uil-angle-right"></i>
                Per Resource Engagement</a></li>
            <li><a routerLink="/working-models/dedicated-team" class="text-foot"><i class="uil uil-angle-right"></i>
                Dedicated Team Model</a></li>
            <li><a routerLink="/working-models/maintenance-contracts" class="text-foot"><i
                  class="uil uil-angle-right"></i> Maintenance Contracts</a></li>
            <li><a routerLink="/working-models/fixed-price" class="text-foot"><i class="uil uil-angle-right"></i> Fixed
                Price & Timeline</a></li>
          </ul>
          <h4 class="text-light footer-head">Company</h4>
          <ul class="list-unstyled footer-list mt-4 {{add_class ? 'row-spacer' : ''}}">
            <li><a routerLink="/about" class="text-foot"><i class="uil uil-angle-right"></i> About Us</a></li>
            <li><a routerLink="/projects-and-portfolios" class="text-foot"><i class="uil uil-angle-right"></i> Portfolio</a></li>
            <li><a routerLink="/services/all" class="text-foot"><i class="uil uil-angle-right"></i> Services &
                Technology</a></li>
            <li><a routerLink="/contact-us" class="text-foot"><i class="uil uil-angle-right"></i> Contact Us</a></li>
          </ul>
        </div>
        <div class="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0 mt-sm-0 pt-2 pt-sm-0">
          <h4 class="text-light footer-head">Newsletter</h4>
          <p class="mt-2">Sign up and receive the latest tips via email.</p>
          <form class="row-spacer" [formGroup]="subscriberForm" (ngSubmit)="onSubmitSubscriber()">
            <div class="row">
              <div class="col-lg-12">
                <div class="foot-subscribe form-group position-relative">
                  <label>Write your email <span class="text-danger">*</span></label>
                  <i data-feather="mail" class="fea icon-sm icons"></i>
                  <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                  <input 
                    type="email" 
                    name="email" 
                    id="emailsubscribe" 
                    class="form-control pl-5 rounded"
                    placeholder="Your email : "
                    formControlName="email"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                  >
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                    <div *ngIf="f.email.errors.email">Email is not valid</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <input type="submit" id="submitsubscribe" name="send" class="btn btn-soft-primary btn-block"
                  value="Subscribe">
              </div>
            </div>
          </form>

          <a routerLink="/landing" class="logo-footer">
            <img src="assets/images/footer-logo.png" height="100" alt="">
          </a>
          <ul class="list-unstyled social-icon social mb-0 mt-4 {{add_class ? '' : ''}}">
            <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
              </a></li>
            <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
              </a></li>
            <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
              </a></li>
            <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
              </a></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
