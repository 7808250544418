import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute,NavigationEnd } from '@angular/router';
import { AuthLayoutService } from '../auth-layout/auth-layout.service';
import { filter, map,mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass']
})
export class DashboardComponent implements OnInit {

  constructor(private router: Router,private activatedRoute: ActivatedRoute,private service: AuthLayoutService) { }

  ngOnInit(): void {
  }

  onActivate(event) {
    let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
            window.scrollTo(0, pos - 200);
        } else {
            window.clearInterval(scrollToTop);
        }
    }, 1);
}

getChild(activatedRoute: ActivatedRoute) {
  if (activatedRoute.firstChild) {
    return this.getChild(activatedRoute.firstChild);
  } else {
    return activatedRoute;
  }

}
}
