import { Component, OnInit,HostBinding } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  @HostBinding('class') classlist = 'dt-page--container';

  constructor() { }

  ngOnInit(): void {
  }

}
